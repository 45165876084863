<template>
  <div class="room-features my-2 d-flex">
    <v-icon class="mr-2">mdi-wifi</v-icon>
    <v-icon class="mr-2">mdi-television</v-icon>
    <v-icon class="mr-2">mdi-ceiling-fan</v-icon>
    <v-icon class="mr-2">mdi-paper-roll</v-icon>
    <v-icon class="mr-2">mdi-food-off-outline</v-icon>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>

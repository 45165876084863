<template>
  <div id="book-component" class="pa-5">
    <section class="booking" v-if="showRooms">
      <div class="booking-header px-5">
        <DatePicker
          label="Check In"
          @dateUpdated="handleReservation($event, 1)"
        />
        <DatePicker
          label="Check Out"
          @dateUpdated="handleReservation($event, 2)"
        />
      </div>
    </section>
    <p v-if="showDateRangeError" class="error py-2 text-center white--text">
      {{ showDateRangeError }}
    </p>
    <section class="room-data" v-if="showRooms">
      <h2 class="component-header mb-5 ml-8">Room Categories</h2>
      <section class="room-list">
        <div class="room" v-for="room in roomData" :key="room._id">
          <section class="room-detail">
            <p class="room-title">{{ room.category }}</p>

            <Features />
          </section>
          <section class="pricing template-between">
            <span class="room-price">INR {{ room.price }}</span>
            <section class="template-between">
              <v-btn small @click="handleRoomUpdate('sub', room._id)">-</v-btn>
              <span class="mx-4 current-room-selected">{{ room.select }}</span>
              <v-btn small @click="handleRoomUpdate('add', room._id)">+</v-btn>
            </section>
          </section>
        </div>
      </section>
    </section>

    <div class="text-center">
      <button
        class="booking-btn"
        v-if="isRoomSelected && showRooms"
        @click="completeRoomSelection"
      >
        Continue
      </button>
    </div>
    <section v-if="!showRooms">
      <h2 class="component-header">Contact Information</h2>
      <v-text-field
        label="Name"
        v-model="user_details.name"
        :error-messages="errors.name"
      ></v-text-field>
      <v-text-field
        label="Email"
        v-model="user_details.email"
        :error-messages="errors.email"
      ></v-text-field>
      <v-text-field
        label="Contact"
        v-model="user_details.contact"
        :error-messages="errors.contact"
      ></v-text-field>
      <p>
        Please make sure you agree with the price breakdown. No further amount
        change will be modified after the payment is received. You can cancel
        the payment anytime 24 hours before your selected check-in time.
      </p>
      <div class="price-breakdown my-5 text-end">
        <DataTable :headers="headers" :data="getPriceBreakdown" />
      </div>
      <v-row>
        <v-col>
          <v-btn @click="showRooms = true" block color="success" outlined
            >Back</v-btn
          ></v-col
        >
        <v-col>
          <v-btn @click="bookRoom" block class="success">Pay</v-btn></v-col
        >
      </v-row>
      <p
        v-if="showBookingSuccess"
        class="mt-5 py-2 success white--text text-center"
      >
        Booking Confirmed. You'll receive an email shortly with booking details
      </p>
      <p
        v-if="showBookingFailure"
        class="mt-5 py-2 error white--text text-center"
      >
        We are facing some issues. If your payment was deducted, it will be
        refunded in next 7 working days.
      </p>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import DatePicker from '@/components/Booking/DatePicker.vue';
import Features from '@/components/Booking/Features.vue';
import DataTable from '@/components/Booking/DataTable.vue';
import { validateTimeSpan, validate, calculateTimeSpan } from './validation';
import { displayRazorpay } from './razorpay';
import { createNewBooking } from './bookings';
import moment from 'moment';
export default {
  components: { DatePicker, Features, DataTable },
  data() {
    return {
      checkIn: '',
      checkOut: '',
      roomData: [],
      showRooms: true,
      showBookingSuccess: false,
      showBookingFailure: false,
      headers: [
        { text: 'Room Type', value: 'category' },
        { text: 'Room Selected', value: 'selected' },
        { text: 'One Day Price', value: 'price' },
        { text: 'Total Price', value: 'total' },
      ],
      showDateRangeError: '',
      user_details: {
        name: '',
        email: '',
        contact: '',
      },
      errors: {
        name: '',
        email: '',
        contact: '',
      },
    };
  },
  async created() {
    const BASE_URL =
      window.location.hostname === 'localhost'
        ? process.env.VUE_APP_DEV_BASE_URL
        : process.env.VUE_APP_PROD_BASE_URL;
    const result = await axios.get(`${BASE_URL}/inventory`);
    result.data.forEach((room) => {
      if (room.bookingEnabled) {
        room.select = 0;
        this.roomData.push(room);
      }
    });
  },
  methods: {
    completeRoomSelection() {
      const dateCheck = validateTimeSpan(this.checkIn, this.checkOut);
      if (!dateCheck[0]) {
        this.showDateRangeError = dateCheck[1];
        setTimeout(() => {
          this.showDateRangeError = '';
        }, 3000);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      this.showRooms = false;
    },
    handleReservation(date, type) {
      type === 1 ? (this.checkIn = date) : (this.checkOut = date);
    },
    handleRoomUpdate(operation, id) {
      let data = this.roomData.find((room) => room._id === id);
      if (operation === 'add' && data.select < data.alloted) {
        data.select += 1;
      }
      if (operation === 'sub' && data.select > 0) {
        data.select -= 1;
      }
    },
    async bookRoom() {
      if (
        !this.user_details.name ||
        !this.user_details.email ||
        !this.user_details.contact
      ) {
        return;
      }
      const validationStatus = this.validateDetails();
      if (validationStatus) {
        let rooms = [];
        this.roomData.forEach((room) => {
          if (room.select > 0) {
            rooms.push({
              category: room.category,
              required: room.select,
            });
          }
        });
        // Booking Details
        const data = {
          checkIn: this.checkIn,
          checkOut: this.checkOut,
          name: this.user_details.name,
          email: this.user_details.email,
          contact: this.user_details.contact,
          rooms,
        };
        try {
          const options = await createNewBooking(data);
          if (!options) {
            this.$router.push('/');
          }
          displayRazorpay(options);
        } catch (error) {
          this.showBookingFailure = true;
          this.showBookingSuccess = false;
        }
      }
    },
    async validateDetails() {
      const nameValid = validate(this.user_details.name, 'name');
      const emailValid = validate(this.user_details.email, 'email');
      const contactValid = validate(this.user_details.contact, 'contact');
      this.errors.name = !nameValid[0] ? nameValid[1] : '';
      this.errors.email = !emailValid[0] ? emailValid[1] : '';
      this.errors.contact = !contactValid[0] ? contactValid[1] : '';
      if (!nameValid[0] || !emailValid[0] || !contactValid[0]) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return false;
      }
      return true;
    },
  },
  computed: {
    isRoomSelected() {
      let roomSelected = false;
      this.roomData.forEach((room) => {
        if (+room.select > 0) {
          roomSelected = true;
          return;
        }
      });
      return roomSelected;
    },
    getPriceBreakdown() {
      const selectedRooms = this.roomData.filter((room) => {
        return +room.select > 0;
      });
      const diff = moment(this.checkOut).diff(this.checkIn, 'days');
      let priceFilter = [];
      selectedRooms.forEach((room) => {
        priceFilter.push({
          category: room.category,
          selected: room.select,
          price: +room.price,
          total: +room.price * diff * +room.select,
        });
      });
      let totalAmount = 0;
      priceFilter.forEach((room) => (totalAmount += +room.total));
      const gst = Number(totalAmount * 12/100)
      priceFilter.push({ category: 'CGST @6%', total: `${gst/2}`})
      priceFilter.push({ category: 'SGST @6%', total: `${gst/2}`})
      priceFilter.push({ category: 'Total Amount', total: `₹ ${totalAmount + gst}` });
      return priceFilter;
    },
  },
};
</script>

<style scoped>
.booking-header {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 600px) {
  .booking-header {
    flex-direction: column;
  }
}
.room {
  padding: 10px 30px;
  background: #f5f5f5;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
}
.room-title {
  font-size: 20px;
  color: #222;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.room-price {
  color: red;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
small {
  color: red;
}
.template-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.current-room-selected {
  font-size: 15px;
}
.component-title {
  font-size: 20px;
  text-align: center;
}
@media (max-width: 850px) {
  .room {
    grid-template-columns: 100%;
  }
}
.booking-btn {
  padding: 10px 20px;
  border-radius: 5px;
  background: #563610;
  color: #fff;
}
</style>

<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="+limit || 5"
    class="elevation-1"
    hide-default-footer
  ></v-data-table>
</template>

<script>
export default {
  props: ['data', 'headers', 'limit'],
};
</script>

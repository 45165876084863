import { updatePaymentStatus } from './bookings';

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
  });
};

const displayRazorpay = async (options) => {
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
  if (!res) {
    alert('load to load razorpay');
    return;
  }
  try {
    const paymentObject = new Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', async function(response) {
      await updatePaymentStatus(options.bookingId, 'Failed / Cancelled');
    });
  } catch (error) {
    await updatePaymentStatus(options.bookingId, 'Failed / Cancelled');
  }
};

export { displayRazorpay };

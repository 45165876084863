import axios from 'axios';

const BASE_URL =
  window.location.hostname === 'localhost'
    ? process.env.VUE_APP_DEV_BASE_URL
    : process.env.VUE_APP_PROD_BASE_URL;
const razorpay_key =
  window.location.hostname === 'localhost'
    ? process.env.VUE_APP_DEV_RAZORPAY_KEY
    : process.env.VUE_APP_PROD_RAZORPAY_KEY;

const updatePaymentStatus = async function(
  bookingId,
  paymentStatus,
  details,
  email_data
) {
  try {
    const data = { bookingId, paymentStatus, details };
    await axios.post(`${BASE_URL}/bookings/update`, data);
    await axios.post(`${BASE_URL}/email/booking-confirmed`, email_data);
    // Now send email for success
  } catch (error) {}
};

const createNewBooking = async function(bookingData) {
  try {
    const booking = await axios.post(`${BASE_URL}/bookings`, bookingData);
    if (!booking.data) {
      throw new Error('Unable to book rooms. Please try again later');
    }
    const data = booking.data;
    const txnData = data.response;
    const options = {
      key: razorpay_key,
      amount: txnData.amount,
      currency: txnData.currency,
      name: 'Hotel Ajay International',
      description: 'Payment for room booking',
      order_id: txnData.id,
      bookingId: txnData.bookingId,
      handler: async function(response) {
        await updatePaymentStatus(
          txnData.bookingId,
          'Success',
          {
            orderId: txnData.orderId,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          },
          {
            bookingId: txnData.bookingId,
            name: bookingData.name,
            email: bookingData.email,
            checkIn: bookingData.checkIn,
            checkOut: bookingData.checkOut,
            amount: +txnData.amount / 100,
          }
        );
        window.location.href = `/booking/${txnData.bookingId}`;
      },
      prefill: {
        name: bookingData.name,
        email: bookingData.email,
        contact: bookingData.contact,
      },
      theme: {
        color: '#3399cc',
      },
    };
    return options;
  } catch (error) {
    return null;
  }
};

export { updatePaymentStatus, createNewBooking };

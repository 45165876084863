<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        class="mr-4"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="updateDate"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['label'],
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
  }),
  methods: {
    updateDate() {
      this.$refs.menu.save(this.date);
      this.$emit('dateUpdated', this.date);
    },
  },
  created() {
    this.$emit('dateUpdated', this.date);
  },
};
</script>

<style scoped></style>

import moment from 'moment';

const validateTimeSpan = (from, to) => {
  try {
    if (calculateTimeSpan(new Date(), from, 'days') < 2) {
      return [false, 'Date range selection is invalid'];
    }
    if (calculateTimeSpan(from, to, 'days') < 1) {
      return [false, 'Invalid Check out'];
    }
    if (calculateTimeSpan(from, to, 'days') > 5) {
      return [
        false,
        'We do not process stay for longer than one week. Please try reaching out to us directly.',
      ];
    }
    return [true, ''];
  } catch (error) {
    return false;
  }
};

const calculateTimeSpan = (from, to, unit) => {
  return moment(to).diff(moment(from), unit);
};

const rule = {
  email: {
    isEmpty: false,
    minLength: 7,
  },
  contact: {
    isEmpty: false,
    minLength: 10,
    maxLength: 14,
  },
  name: {
    isEmpty: false,
    minLength: 5,
  },
};
const validate = (fieldValue, rules) => {
  const appliedRule = rule[rules];
  const fieldLength = fieldValue.trim().length;
  if (appliedRule.isEmpty && fieldLength <= 0) {
    return [false, `${rules} cannot be empty`];
  }
  if (appliedRule.minLength && appliedRule.minLength > fieldLength) {
    if (fieldLength === 0) {
      return [false, `${rules} cannot be empty`];
    }
    return [
      false,
      `${rules} cannot be less than ${appliedRule.minLength} characters`,
    ];
  }
  if (appliedRule.maxLength && appliedRule.maxLength < fieldLength) {
    return [false, `${rules} cannot be greater than ${appliedRule.maxLength}`];
  }
  return [true, 'Everything looks good'];
};
export { validateTimeSpan, validate, calculateTimeSpan };
